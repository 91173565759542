import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import { Container, ContentBox, P } from "./styledcomponents"
import globals from "./globals"
import styled from "styled-components"

const Box = styled(ContentBox)`
  backdrop-filter: blur(3px);

  @media (min-width: ${globals.media.flexBox2}) {
    border-radius: 20px 20px 20px 20px;
  }
`

function Banner(props) {
  return (
    <Container
      padding="0"
      backgroundColor={globals.color.darkAccent}
      height="40vh"
      margin="0 0 3em 0"
      alignItems="center"
    >
      <StaticImage
        src="../images/Yoga/Online-Yoga.jpg"
        alt="Onlin Yoga via Zoom"
        style={{ height: "100%" }}
      />
      <ContentBox
        maxWidth={globals.media.flexBox2}
        position="absolute"
        margin="0"
        padding="0"
        width="100%"
      >
        <Box
          backgroundColor="#FFFFFFaa"
          margin="0 0 3em 0"
          radius="0 20px 20px 0"
          maxWidth="280px"
        >
          <P>
            Unterstütze dein Immunsystem & deine mentale Gesundheit durch TLA
            Online Stunden{" "}
            <Link to="/stundenplan">Jetzt buchen</Link>
          </P>
        </Box>
      </ContentBox>
    </Container>
  )
}

export default Banner
